.slide-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55px;
  background: #003398;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 40px 0;
  z-index: 4;

  span {
    width: 25px;
    color: #fff;
    display: block;
    margin: 0 auto;
  }

  &--next {
    right: 0;

    span {
      transform: rotateZ(-90deg);
    }
  }

  &--prev {
    left: 0;

    span {
      transform: rotateZ(90deg);
    }
  }
}