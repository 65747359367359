body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
} */